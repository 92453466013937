@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import './constants/styles.css';

@font-face {
  font-family: 'AcidGrotesk';
  src: local('AcidGrotesk-Regular'),
    url(./assets/fonts/AcidGrotesk-Regular.otf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'AcidGrotesk-Medium';
  src: local('AcidGrotesk-Medium'),
    url(./assets/fonts/AcidGrotesk-Medium.otf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'AcidGrotesk-Bold';
  src: local('AcidGrotesk-Bold'),
    url(./assets/fonts/AcidGrotesk-Bold.otf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
}

h1 {
  font-family: 'AcidGrotesk-Bold', system-ui;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

html,
body {
  font-family: 'Mulish', sans-serif;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  border-radius: 6px;
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--grey300);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 6px;
  background: var(--grey400);
}
/* end custom scrollbar */

/* DatePicker dropdown */
.MuiPickersLayout-root button {
  color: var(--grey900);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.5px;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  background-color: var(--green600) !important;
  color: var(--grey900);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.5px;
}
.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today {
  background-color: var(--blue300);
  border: none;
  color: var(--grey900);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.5px;
}

.MuiPaper-root.MuiPaper-elevation {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);
}

.MuiPickersYear-root button.Mui-selected {
  background-color: var(--green600) !important;
  color: var(--grey900);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.5px;
}

.MuiPickersLayout-root button[aria-selected='true']:hover {
  background-color: var(--green600);
}

.MuiPickersLayout-root button:hover {
  background-color: var(--green50);
}

/* TimePicker dropdown */
.MuiPickersLayout-contentWrapper.MuiPickersLayout-contentWrapper {
  border-radius: 8px 8px 0px 0px;

  background: white;
  padding: 12px;
}

.MuiMultiSectionDigitalClock-root.MuiMultiSectionDigitalClock-root {
  border-bottom: none;
  width: fit-content;
}
.MuiMultiSectionDigitalClock-root.MuiMultiSectionDigitalClock-root
  > ul:first-child {
  padding-right: 10px;
}
.MuiMultiSectionDigitalClock-root.MuiMultiSectionDigitalClock-root
  > ul:last-child {
  padding-left: 10px;
  border-color: var(--grey200);
}

.MuiDialogActions-root.MuiDialogActions-spacing.MuiPickersLayout-actionBar.MuiDialogActions-root {
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid var(--grey200);
  background: white;
  box-shadow: 0px 0px 6px 0px rgba(131, 131, 131, 0.05);
  color: var(--blue800);
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  padding: 12px;
}

.MuiDialogActions-root.MuiDialogActions-spacing.MuiPickersLayout-actionBar.MuiDialogActions-root
  > button {
  display: flex;
  justify-content: flex-end;
  color: var(--blue800);
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  padding: 0px;
}
.MuiDialogActions-root.MuiDialogActions-spacing.MuiPickersLayout-actionBar.MuiDialogActions-root
  > button:hover {
  background: white;
}

.MuiMultiSectionDigitalClock-root.MuiMultiSectionDigitalClock-root > li {
  width: 36px;
  padding: 4px 8px;
  margin: 0;
  color: var(--grey800);
  text-align: center;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.04px;
}

.MuiMultiSectionDigitalClock-root.MuiMultiSectionDigitalClock-root
  > li.Mui-selected {
  border-radius: 4px;
  background: var(--blue100);
}

.no-scroll {
  overflow: hidden;
}
